import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isArray } from 'lodash';

@Pipe({
  name: 'mapLeadSources'
})
export class MapLeadSourcesPipe implements PipeTransform {
  currentLang: string;
  constructor(
    private _translateService: TranslateService
  )
  {
    this.currentLang = this._translateService.currentLang === 'es' ? 'es_AR' : this._translateService.currentLang;
  }

  transform(array): any {
    // tslint:disable-next-line:prefer-const
    let result = [];
    if(isArray(array) && array.length > 0 ){
      // tslint:disable-next-line:prefer-for-of 
      for (let i = 0; i < array.length; i++)
      {
        result.push( this._translateService.instant(array[i]))
      }
    }
    return result.join(' , ');
  }

}
