import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ViewChild,
    AfterViewChecked,
    ChangeDetectorRef,
    ViewChildren,
    QueryList,
    AfterViewInit,
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { GridBoardsService } from 'app/services/grid-boards.service';
import { Subject, merge, combineLatest } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { FuseUtils } from '@fuse/utils';
import {
    cloneDeep,
    findIndex,
    remove,
    pickBy,
    identity,
    indexOf,
    find,
    forEach,
    compact,
    map as lodashMap,
    take,
    uniq,
    sum,
    includes,
    isUndefined,
    isNull,
    isNumber,
    defaultsDeep,
} from 'lodash';
import { AdvanceSearchComponent as AccountsAdvanceSearchComponent } from '../../accounts/advance-search/advance-search.component';
import { AdvanceSearchComponent as OwnersAdvanceSearchComponent } from '../../owners/advance-search/advance-search.component';
import { FormGroup } from '@angular/forms';
import { QuickAddComponent } from '../../accounts/quick-add/quick-add.component';
import { AuthenticationService } from 'app/services';
import { environment } from 'environments/environment';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { ScrumboardBoardListComponent } from './list/list.component';
import { AccountMassUpdateComponent } from '../../accounts/account-mass-update/account-mass-update.component';
import { AccountsService } from 'app/services/accounts.service';
import { OwnerMassUpdateComponent } from '../../owners/owner-mass-update/owner-mass-update.component';
import { OwnersService } from 'app/services/owners.service';
import { GlobalFuntions } from 'app/_helpers';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CrmMenuService } from 'app/services/crm-menu.service';

@Component({
    selector: 'grid-data',
    templateUrl: './grid-data.component.html',
    styleUrls: ['./grid-data.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class GridDataComponent
    implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
    relatedTo: any;
    board: any;
    id: number;
    listSettings: any;
    searchFields: any;
    selectedData = [];
    selectedIds = [];
    selectedEmails = [];
    assignedEmails: any;
    private _unsubscribeAll: Subject<any>;
    loader: boolean;
    quickSearch: boolean;
    routFor: string;
    rpaId: any;
    property: any;
    sorting = true as boolean;

    @ViewChild('settingsSidenav', { static: false })
    settingsSidenav: MatSidenav;
    @ViewChildren(ScrumboardBoardListComponent)
    scrumboardBoardList!: QueryList<ScrumboardBoardListComponent>;
    totalCount: number;
    roaId: any;
    searchDialogRef: MatDialogRef<AccountMassUpdateComponent, any>;
    ownerDialogRef: MatDialogRef<OwnerMassUpdateComponent, any>;
    commercial: any;
    mooring: any;
    rpcId: any;
    rpmId: any;
    rpbId: any;
    currentUser: any;
    advanceSearchFields = {};
    isDisabled = false as boolean;
    boat: any;
    quickSearchSave: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _gridBoardsService: GridBoardsService,
        private _matDialog: MatDialog,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService,
        private _snackBar: MatSnackBar,
        private _timeLineData: TimeLineDataService,
        private _fuseSidebarService: FuseSidebarService,
        private cdRef: ChangeDetectorRef,
        private _accountsService: AccountsService,
        private _ownersService: OwnersService,
        public _globalFuntions: GlobalFuntions,
        public _crmMenuService: CrmMenuService
    ) {
        this.currentUser = this._authenticationService.currentUserValue;
        this.selectedData = [];
        this.selectedIds = [];
        this.selectedEmails = [];
        this.assignedEmails = [];
        this.id = +this.route.snapshot.paramMap.get('id');
        this._unsubscribeAll = new Subject();
        this.route.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((v) => (this.relatedTo = v.relatedTo));
    }

    ngOnInit(): void {
        if (this.id === 1 || this.id === 2) {
            if (this.currentUser.user_role !== 'admin') {
                this.sorting = false;
            }
        }
        if (
            this.currentUser.user_role === 'agent' ||
            this.currentUser.user_role === 'agent_medium' ||
            this.currentUser.user_role === 'admin_agent_light'
        ) {
            this.isDisabled = true;
        }
        this.routFor = this.relatedTo === 'account' ? 'accounts' : 'owners';
        this.loader = true;
        this._gridBoardsService
            .getBoard(this.id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((board) => {
                this.board = board;
                this.loader = false;
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onBoardNameChanged(newName: any): void {
        this.board.title = newName;
        this.updateBoard();
    }

    onGridAdd(newListName: any): void {
        const list = {
            id: FuseUtils.generateGUID(),
            name: newListName,
            query: {
                condition: 'and',
                rules: [],
            },
        };
        this.board.grids.push(list);
        this.updateBoard();
    }

    onGridDrop(event: any): void {
        this.updateBoard();
    }

    updateBoard(): void {
        this.selectedData = [];
        this.selectedIds = [];
        this.selectedEmails = [];
        this.assignedEmails = [];
        this.loader = true;
        this._gridBoardsService
            .addEditBoards(this.board, this.board._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (board) => {
                    this.board = board;
                    this.loader = false;
                },
                (err) => {
                    this.loader = false;
                    console.log(err);
                }
            );
    }

    deleteBoard(): void {
        const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
        });
        dialog.componentInstance.confirmMessage = this._translateService.instant(
            'Are you sure you want to delete board you will not abel to recover it again?'
        );
        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this._gridBoardsService
                    .deleteBoard(this.id)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(() => {
                        if (
                            includes(this.router.url, 'master-data/owners/grid')
                        ) {
                            this.router.navigate(['/master-data/owners/grid']);
                        } else {
                            this.router.navigate([
                                '/master-data/accounts/grid',
                            ]);
                        }
                    });
            }
        });
    }

    onOppenSettings(event: any): void {
        this.listSettings = cloneDeep(event);
        this.settingsSidenav.toggle();
    }

    onSaveSettings(event: any): void {
        if (event) {
            const index = findIndex(this.board.grids, { id: event.id });
            this.board.grids[index] = this.listSettings;
            this.updateBoard();
        }
        this.settingsSidenav.close();
    }

    onDeleteSettings(event: any): void {
        if (event) {
            const dialog = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false,
            });
            dialog.componentInstance.confirmMessage = this._translateService.instant(
                'Are you sure you want to delete list you will not abel to recover it again?'
            );
            dialog.afterClosed().subscribe((result) => {
                if (result) {
                    remove(this.board.grids, (el: any) => {
                        return el.id === event.id;
                    });
                    this.updateBoard();
                }
            });
        }
        this.settingsSidenav.close();
    }

    advancedSearchForm(): void {
        if (this.relatedTo === 'account') {
            const dialogRef = this._matDialog.open(
                AccountsAdvanceSearchComponent,
                {
                    panelClass: 'form-dialog',
                    data: this.advanceSearchFields,
                }
            );
            dialogRef.afterClosed().subscribe((result: FormGroup) => {
                if (result) {
                    let arrayList = ['commercials_interested','cash_buyer','filter_projects','residential_newsletter','gdpr_status','status','created_date_from','created_date_to','last_updated_from','last_updated_to','properties_interested'];
                    const searchFields = this._globalFuntions.clearObject(
                        result.value
                    );
                    this.advanceSearchFields = searchFields;
                    this.searchFields = searchFields
                    if (
                        this.searchFields.status &&
                        this.searchFields.status !== 'inactive'
                    ) {
                        delete this.searchFields.inactive_message;
                    }
                    if(this._crmMenuService.isCommercialModule){
                        if('property_types' in this.searchFields){
                            this.searchFields["type_one"] = this.searchFields["property_types"]
                            delete this.searchFields["property_types"];
                        }
                        if('transaction_types' in this.searchFields){
                            this.searchFields["transaction_type"] = this.searchFields["transaction_types"]
                            delete this.searchFields["transaction_types"];
                        }
                        if ('buy_price_low' in this.searchFields) {
                            this.searchFields["buy_price_from"] = {$gte: String(this.searchFields["buy_price_low"])}; 
                            delete this.searchFields["buy_price_low"];
                        }
                        if ('buy_price_high' in this.searchFields) {
                            this.searchFields["buy_price_to"] = {$lte: String(this.searchFields["buy_price_high"])};
                            delete this.searchFields["buy_price_high"];
                        }
                        const objectFilters = Object.keys(this.searchFields).reduce((result, key) => {
                            if (!arrayList.includes(key)) {
                                result['commercial_profile.' + key] = searchFields[key];
                            } else {
                                result[key] = searchFields[key];
                            }
                            return result;
                        }, {});
                        this.searchFields = objectFilters;
                    }
                    this._gridBoardsService.setGridSearch(this.searchFields);
                }
            });
        }
        if (this.relatedTo === 'owner') {
            const dialogRef = this._matDialog.open(
                OwnersAdvanceSearchComponent,
                {
                    panelClass: 'form-dialog',
                    data: {},
                }
            );
            dialogRef.afterClosed().subscribe((result: FormGroup) => {
                if (result) {
                    const searchFields = pickBy(result.value, identity);
                    this.searchFields = searchFields;
                    this._gridBoardsService.setGridSearch(this.searchFields);
                }
            });
        }
    }

    onSelectData(event: any): void {
        if (event) {
            const action = event[0] as boolean;
            const data = event[1] as any;
            if (action) {
                if (!find(this.selectedData, { _id: data._id })) {
                    this.selectedData = [...this.selectedData, data];
                }
                if (data._id && indexOf(this.selectedIds, data._id) < 0) {
                    this.selectedIds = [...this.selectedIds, data._id];
                }
                if (
                    data.email &&
                    indexOf(this.selectedEmails, data.email) < 0
                ) {
                    this.selectedEmails = [...this.selectedEmails, data.email];
                }
                data?.partners?.forEach((partner) => {
                    if (
                        partner.email &&
                        indexOf(this.selectedEmails, partner.email) < 0
                    ) {
                        this.selectedEmails = [
                            ...this.selectedEmails,
                            partner.email,
                        ];
                    }
                });
                if (this.relatedTo === 'owner') {
                    if (data.assign_agent || data.created_by) {
                        if (
                            includes(data.assign_agent, this.currentUser._id) ||
                            data.created_by === this.currentUser._id
                        ) {
                            this.assignedEmails = [
                                ...this.assignedEmails,
                                data.email,
                            ];
                        }
                    }
                } else {
                    if (data.assigned_to || data.created_by) {
                        if (
                            includes(
                                data.assigned_to,
                                this.currentUser.user_name
                            ) ||
                            data.created_by === this.currentUser._id
                        ) {
                            this.assignedEmails = [
                                ...this.assignedEmails,
                                data.email,
                            ];
                        }
                    }
                }
            }
            if (!action) {
                remove(
                    this.selectedData,
                    (account) => account._id === data._id
                );
                remove(this.selectedIds, (id) => id === data._id);
                remove(this.selectedEmails, (email) => email === data.email);
                remove(this.assignedEmails, (email) => email === data.email);
            }
        }
    }

    onSelectAllData(event: any): void {
        if (event) {
            const action = event[0] as boolean;
            const data = event[1] as any;
            forEach(data, (account) => {
                this.onSelectData([action, account]);
            });
        }
    }

    onOpenTimeline(event: any): void {
        this._timeLineData.setModelDataValue(event);
        this._timeLineData.setRelatedToValue(this.relatedTo);
        this._fuseSidebarService.getSidebar('timeline').toggleOpen();
    }

    relatedAccounts(event: any): void {
        this.roaId = event._id;
        this._fuseSidebarService.getSidebar('related-accounts').toggleOpen();
    }

    viewProperty(event: any): void {
        this.property = event;
        this._fuseSidebarService.getSidebar('properties-views').toggleOpen();
    }

    openProperties(event: any): void {
        this.rpaId = event;
        this._fuseSidebarService.getSidebar('properties').toggleOpen();
    }

    viewCommercial(event: any): void {
        this.commercial = event;
        this._fuseSidebarService.getSidebar('properties-views').toggleOpen();
    }

    viewMooring(event: any): void {
        this.mooring = event;
        this._fuseSidebarService.getSidebar('properties-views').toggleOpen();
    }

    viewBoat(event: any): void {
        this.boat = event;
        this._fuseSidebarService.getSidebar('properties-views').toggleOpen();
    }

    openCommercials(event: any): void {
        this.rpcId = event;
        this._fuseSidebarService.getSidebar('properties').toggleOpen();
    }

    openMoorings(event: any): void {
        this.rpmId = event;
        this._fuseSidebarService.getSidebar('properties').toggleOpen();
    }
    openBoats(event: any): void {
        this.rpbId = event;
        this._fuseSidebarService.getSidebar('properties').toggleOpen();
    }
    onOpenedChanged(event: any): void {
        if ((this.rpaId && !event) || (this.rpcId && !event)) {
            this.updateBoard();
        }
        if (!event) {
            delete this.roaId;
            delete this.rpaId;
            delete this.rpcId;
            delete this.rpmId;
            delete this.rpbId;
            delete this.property;
            delete this.commercial;
            delete this.mooring;
            delete this.boat;
        }
    }

    onRefreshLists(event?: any): void {
        this.updateBoard();
    }

    togalQuickSearch(): void {
        this.quickSearch = !this.quickSearch;
    }

    onQuickSearch(event: any): void {
        if (!event) {
            this._gridBoardsService.setGridSearch('');
            this.searchFields = {};
            this.quickSearchSave = {};
            this.advanceSearchFields = {};
        } else {
            this.quickSearchSave = this._globalFuntions.cleanObject(event.value);
            const searchFields = this._globalFuntions.clearObject(event.value);
            this.searchFields = searchFields;
            this._gridBoardsService.setGridSearch(this.searchFields);
            setTimeout(() => {
                this.quickSearch = false;
            });
        }
    }

    toggleSidebar(name: string): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    massUpdate(): void {
        if (this.relatedTo === 'account') {
            this.searchDialogRef = this._matDialog.open(
                AccountMassUpdateComponent,
                {
                    panelClass: 'mass-dialog',
                    data: { accounts: this.selectedIds },
                }
            );
            this.searchDialogRef
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((response: any) => {
                    if (response) {
                        this.loader = true;
                        this.updateBoard();
                        // let updateRequests = [];
                        // forEach(this.selectedData, (account: any) => {
                        //     account.csvimport = 'true';
                        //     if (response.office_append && response.offices && response.offices.length && account.offices) {
                        //         forEach(response.offices, (ov) => {
                        //             if (account.offices.indexOf(ov) < 0) {
                        //                 account.offices.push(ov);
                        //             }
                        //         });
                        //     } else if (response.offices && response.offices.length) {
                        //         account.offices = response.offices;
                        //     }
                        //     if (response.assigne_append && response.assigned_to && response.assigned_to.length && account.assigned_to) {
                        //         forEach(response.assigned_to, (at) => {
                        //             if (Array.isArray(account.assigned_to) && account.assigned_to.indexOf(at) < 0) {
                        //                 account.assigned_to.push(at);
                        //             }
                        //         });
                        //     } else if (response.assigned_to && response.assigned_to.length) {
                        //         account.assigned_to = response.assigned_to;
                        //     }
                        //     if (response.gdpr_status) {
                        //         account.gdpr_status = response.gdpr_status;
                        //     }
                        //     if (response.status) {
                        //         account.status = response.status;
                        //     }
                        //     account.delete_flag = response.delete_flag;
                        //     updateRequests = [...updateRequests, this._accountsService.update(account)];
                        // });
                        // combineLatest(updateRequests)
                        //     .pipe(takeUntil(this._unsubscribeAll))
                        //     .subscribe(() => this.updateBoard(), () => this.updateBoard());
                    }
                });
        } else if (this.relatedTo === 'owner') {
            this.ownerDialogRef = this._matDialog.open(
                OwnerMassUpdateComponent,
                {
                    panelClass: 'mass-dialog',
                    data: { owners: this.selectedIds },
                }
            );
            this.ownerDialogRef
                .afterClosed()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((response: any) => {
                    if (response) {
                        this.loader = true;
                        let updateRequests = [];
                        forEach(this.selectedData, (owner: any) => {
                            // owner.csvimport = 'true';
                            if (
                                response.office_append &&
                                response.offices &&
                                response.offices.length &&
                                owner.offices
                            ) {
                                forEach(response.offices, (ov) => {
                                    if (owner.offices.indexOf(ov) < 0) {
                                        owner.offices.push(ov);
                                    }
                                });
                            } else if (
                                response.offices &&
                                response.offices.length
                            ) {
                                owner.offices = response.offices;
                            }
                            if (
                                response.assigne_append &&
                                response.assign_agent &&
                                response.assign_agent.length &&
                                owner.assign_agent
                            ) {
                                forEach(response.assign_agent, (at) => {
                                    if (owner.assign_agent.indexOf(at) < 0) {
                                        owner.assign_agent.push(at);
                                    }
                                });
                            } else if (
                                response.assign_agent &&
                                response.assign_agent.length
                            ) {
                                owner.assign_agent = response.assign_agent;
                            }
                            if (response.gdpr_status) {
                                owner.gdpr_status = response.gdpr_status;
                            }
                            owner.delete_flag = response.delete_flag;
                            updateRequests = [
                                ...updateRequests,
                                this._ownersService.update(owner),
                            ];
                        });
                        combineLatest(updateRequests)
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe(
                                () => this.updateBoard(),
                                () => this.updateBoard()
                            );
                    }
                });
        }
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngAfterViewInit(): void {
        this.scrumboardBoardList.changes
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                const lists = data.toArray();
                let totalCountSubscriber = [];
                forEach(lists, (list: ScrumboardBoardListComponent) => {
                    totalCountSubscriber = [
                        ...totalCountSubscriber,
                        list.listDataTotal,
                    ];
                });
                combineLatest(totalCountSubscriber).subscribe((total) => {
                    remove(
                        total,
                        (n) => isUndefined(n) || isNull(n) || !isNumber(n)
                    );
                    this.totalCount = sum(total);
                });
            });
    }
    moveToOwnerList() {
        this.router.navigate(['master-data/owners/list'], { state: { data: "owners/grid" } });
    }
}
