import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from 'app/services';
import { AppProviders } from 'app/_helpers';
import { find, isEmpty, includes, isArray } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    currentLang: any;

    constructor(
        private router: Router,
        private _authenticationService: AuthenticationService,
        private _appProviders: AppProviders,
        private _translateService: TranslateService
    ) {
        this._translateService.onLangChange
            .pipe(startWith(''))
            .subscribe(() => {
                this.currentLang =
                    this._translateService.currentLang === 'es'
                        ? 'es_AR'
                        : this._translateService.currentLang;
            });
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): any {
        const currentUser = this._authenticationService.currentUserValue;
        const currentAgency = this._authenticationService.currentAgencyValue;  
        if (currentUser) {
            if (state.url == '/dashboard/whatsapp' && !currentAgency?.whatsapp_module){
                this._authenticationService.logout();
                return false;
            }
            let item: any = find(this._appProviders.siteMenuItems, {
                url: state.url,
            });
            if (isEmpty(item)) {
                item = find(
                    this._appProviders.siteMenuItems,
                    (siteMenuItem: any) =>
                        siteMenuItem.url &&
                        includes(state.url, siteMenuItem.url)
                );
            }
            if (!isEmpty(item)) {
                let fcUrl: any;
                if (
                    currentUser.user_role === 'free_user' &&
                    item.freemium_contracts &&
                    isArray(item.freemium_contracts)
                ) {
                    fcUrl = find(item.freemium_contracts, {
                        route: state.url,
                        lang: this.currentLang,
                    });
                    if (!fcUrl) {
                        fcUrl = find(item.freemium_contracts, {
                            route: state.url,
                            lang: 'en',
                        });
                    }
                }
                if (fcUrl) {
                    this.router.navigate([
                        '/system-configurations/n/page/view',
                        fcUrl._id,
                    ]);
                    return true;
                } else if (
                    (currentUser.user_role === 'mls_user' ||
                        currentUser.user_role === 'mls_pro') &&
                    includes(item.url, 'master-data/accounts/grid')
                ) {
                    this.router.navigate(['/master-data/accounts/list']);
                    return true;
                }
                else if (
                    (currentUser.user_role === 'mls_user' ||
                        currentUser.user_role === 'mls_pro') &&
                    includes(item.url, 'agencies')
                ) {
                    return true;
                } else if (includes(item.access_roles, currentUser.user_role)) {
                    return true;
                } else {
                    this._authenticationService.logout();
                    return false;
                }
            } else {
                return true;
            }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url },
        });
        return false;
    }
}
